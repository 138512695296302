import * as React from 'react'
import { Link } from "gatsby"
import Logo from "@images/logos/sheetsync-logo-light.svg"
import Logomark from "@images/logos/sheetsync-logomark.svg"
import Google from "@images/icons/google.png"
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default class Nav extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            position: null
        }
    }

    open = () => {
        this.setState({
            open: true
        })
    }

    close = () => {
        this.setState({
            open: false
        })
    }

    render() {

        let mobile
        if (!this.state.open) {
            mobile = { display: 'none' }
        }

        let classes = "transition duration-200"

        return (
            <>
                <div className={classes}>
                    <div className="max-w-6xl mx-auto p-6 py-7">
                        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
                            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                                <div className="flex items-center justify-between w-full md:w-auto">
                                    <Link to="/">
                                        <img className="h-8 w-auto sm:h-8 inline mr-2" src={Logo} alt="Sheetsync" />
                                    </Link>
                                    <div className="-mr-2 flex items-center md:hidden">

                                        <button onClick={this.open} type="button" className="inline-flex items-center justify-center p-2 rounded text-gray-600 hover:text-gray-800 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <nav className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
                                <Link to="/pricing" className="basis font-medium text-base my-3 leading-6 text-gray-800 hover:text-blue-800 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                                    Pricing
                                </Link>
                                <span className="inline-flex rounded shadow ml-6">
                                    <a href="https://gsuite.google.com/marketplace/app/sheetsync/198068442022" target="_blank" rel="noopener noreferrer" className="system inline-flex items-center px-5 py-3 text-base leading-6 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                                        Install on Google Sheets
                                    </a>
                                </span>
                            </nav>
                        </nav>
                    </div>
                    {/* Mobile */}
                    <div style={mobile} className="absolute top-0 inset-x-0 p-2 md:hidden z-40">
                        <div className="rounded-lg shadow-2xl transition transform origin-top-right" >
                            <div className="rounded-lg bg-white shadow-xs overflow-hidden">
                                <div className="px-5 pt-4 flex items-center justify-between">
                                    <div>
                                        <img className="h-8 w-auto" src={Logomark} alt="Sheetsync" />
                                    </div>
                                    <div className="-mr-2">
                                        <button onClick={this.close} type="button" className="inline-flex items-center justify-center p-2 rounded text-gray-600 hover:text-gray-800 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="px-2 pt-2 pb-3">
                                    <Link to="/pricing" className="mt-1 basis block px-3 py-2 rounded text-base font-medium text-gray-800 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Pricing</Link>
                                </div>
                                <div>
                                    <a href="https://gsuite.google.com/marketplace/app/sheetsync/198068442022" target="_blank" rel="noopener noreferrer" className="w-full system flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-3 md:text-md md:px-6">
                                        Install on Google Sheets
                  					</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
