import * as React from "react"
import Website from "@src/components/wrappers/website"
import Nav from "@src/components/marketing/headerAlt"
import FAQ from "@components/marketing/faq"
import CTA from "@components/marketing/cta"
import Footer from "@components/marketing/footer"

const seo = {
	url: "/pricing",
	title: "Sheetsync Pricing • Try it free for 14 days",
	description: "Try Sheetsync free for 14 days to automatically sync your finances with your spreadsheet. Sheetsync makes it easy to track your personal finances in Google Sheets by automatically syncing all your financial accounts with your spreadsheet."
}

export default class Pricing extends React.Component {
	render() {
		return (
			<Website seo={seo} marketing>
				<Nav />
				<div className="text-center px-6 py-10 sm:py-12 md:py-16">
					<h1 className="mx-auto text-gray-900 font-semibold leading-tighter text-3xl sm:text-5xl md:text-55xl lg:text-55xl">
						Try it free for 14 days.
    		</h1>
					<p className="text-gray-700 text-md mt-3 mx-auto max-w-lg sm:text-lg sm:max-w-xl md:text-xl md:max-w-2xl">
						Start today and save 25% as an early adopter.
    		</p>
				</div>




				<div className="pb-4 mt-4 mb-16 px-4">
					<div className="relative">
						<div className="absolute inset-0 h-5/12 bg-white"></div>
						<div className="relative z-10 max-w-sm mx-auto px-2">
							<div className=" mx-auto">
								<div className="mt-0 shadow-lg rounded-lg overflow-hidden border-blue-500 border-4">
									<div className="absolute inset-x-0 top-0 transform translate-y-px">
										<div className="flex justify-center transform -translate-y-1/2">
											<span className="basis inline-flex rounded-full bg-blue-500 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
												Save 25%
                  					</span>
										</div>
									</div>
									<div className="bg-white rounded-t-lg px-4 pt-10 pb-8">
										<div>
											<h2 className="basis mb-6 text-center text-2xl leading-9 font-medium font-display text-gray-900 sm:-mx-6">
												Annual Plan
                  </h2>
											<div className="flex items-center justify-center font-display basis">
												<span className="text-right font-medium text-xl leading-8 text-gray-400 tracking-normal line-through sm:text-2xl sm:leading-9">
													$79
                    </span>
												<span className="px-4 flex items-start text-4xl leading-none tracking-tight font-medium text-gray-900 sm:text-5xl">
													<span className="mt-1 sm:mt-2 mr-1 text-xl leading-none sm:text-3xl">
														$
                        </span>
													<span>
														59
                        </span>
												</span>
												<span className="text-xl leading-8 text-gray-400 font-medium tracking-normal sm:text-2xl sm:leading-9">
													USD
                    </span>
											</div>
											<div className="mt-6 flex items-center justify-center font-display font-medium basis text-gray-400">
												<span>That's only $4.92 / month.</span>
											</div>
										</div>
									</div>
									<div className="p-6 sm:p-8 bg-gray-100 rounded-b-lg">
										<div className="mb-8 rounded-md shadow">
											<a href="https://gsuite.google.com/marketplace/app/sheetsync/198068442022" target="_blank" rel="noopener noreferrer" className="w-full system flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-3 md:text-md md:px-6">
												Install on Google Sheets
									</a>
										</div>
										<ul>
											<li className="flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-6 w-6 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 text-gray-700">
													Automatically track your entire financial life in Google Sheets.
                    					</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-6 w-6 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 text-gray-700">
													Link bank accounts, credit cards, investments, loans, and more.
                    					</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-6 w-6 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 text-gray-700">
													Build your own spreadsheets with the help of powerful templates.
                    					</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-6 w-6 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 text-gray-700">
													Receive automatic spending and balance updates every day.
                    					</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-6 w-6 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 text-gray-700">
													Responsive and friendly support.
                    					</p>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FAQ />
				<CTA />
				<Footer />
			</Website>
		)
	}
}