import React from "react"
import { Link } from "gatsby"

const FAQ = () => (
    <div className="bg-gray-50">
        <div className="max-w-5xl mx-auto py-12 px-6 lg:py-16 lg:px-8">
            <h2 className="text-2xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 text-center">
                Frequently asked questions
            </h2>
            <div className="mt-10 pt-6">
                <dl>
                    <div className="md:grid md:grid-cols-12 md:gap-8">
                        <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                            How often will my spreadsheets update with new transactions?
                        </dt>
                        <dd className="mt-2 md:mt-0 md:col-span-7">
                            <p className="text-sm sm:text-base leading-6 text-gray-500">
                                Sheetsync updates your spreadsheet with new spending and transactions multiple times each the day. Account balances are updated daily.
                            </p>
                        </dd>
                    </div>
                    <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                        <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                            Can Sheetsync link with my specific financial account? 
                        </dt>
                        <dd className="mt-2 md:mt-0 md:col-span-7">
                            <p className="text-sm sm:text-base leading-6 text-gray-500">
                                Probably. Sheetsync uses <a href="https://plaid.com" target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">Plaid</a> to securely connect to 12,000+ financial institutions worldwide, from the smallest local credit unions to the biggest multinational banks. The best way to find out if your specific bank is compatible is to start a free trial.
                            </p>
                        </dd>
                    </div>
                    <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                        <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                            Is my financial information private and secure?
                        </dt>
                        <dd className="mt-2 md:mt-0 md:col-span-7">
                            <p className="text-sm sm:text-base leading-6 text-gray-500">
                                The privacy and security of your data is incredibly important. Sheetsync never sees your bank credentials, sells your data, or serves ads, and we never view your transaction info without your expressed permission. <Link to="/privacy-and-security" className="text-blue-800 hover:underline">Learn more about our commitment to your privacy.</Link>
                            </p>
                        </dd>
                    </div>
                    <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                        <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                            What if my question hasn't been answered?
                        </dt>
                        <dd className="mt-2 md:mt-0 md:col-span-7">
                            <p className="text-sm sm:text-base leading-6 text-gray-500">
                                Contact us via <a className="text-blue-800 hover:underline" href="mailto:support@getsheetsync.com">email</a> and we'll be happy to answer all your questions!
                            </p>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
)

export default FAQ